<template>
    <div class="influence-factor" v-if="showCanvas">
        <div class="canvas-box">
            <div class="min-tit">环境</div>
            <div class="canvas-content">
                <div class="canvas-item" v-if="brightness.data">
                    <echarts :echarts="brightness.data"></echarts>
                </div>
                <div class="canvas-item" v-if="colorTemperature.data">
                    <echarts :echarts="colorTemperature.data"></echarts>
                </div>
                <div class="canvas-item" v-if="lightSource.data">
                    <echarts :echarts="lightSource.data"></echarts>
                </div>
                <div class="canvas-item" v-if="fuzziness.data">
                    <echarts :echarts="fuzziness.data"></echarts>
                </div>
            </div>
        </div>
        <div class="canvas-box">
            <div class="min-tit">活动</div>
            <div class="canvas-content">
                <div class="canvas-item" v-if="posture.data">
                    <echarts :echarts="posture.data"></echarts>
                </div>
                <div class="canvas-item" v-if="distance.data">
                    <echarts :echarts="distance.data"></echarts>
                </div>
                <div class="canvas-item" v-if="glasses.data">
                    <echarts :echarts="glasses.data"></echarts>
                </div>
                <div class="canvas-item" v-if="makeupType.data">
                    <echarts :echarts="makeupType.data"></echarts>
                </div>
                <div class="canvas-item" v-if="expression.data">
                    <echarts :echarts="expression.data"></echarts>
                </div>
            </div>
        </div>
        <div class="canvas-box">
            <div class="min-tit">人群</div>
            <div class="canvas-content">
                <div class="canvas-item" v-if="sex.data">
                    <echarts :echarts="sex.data"></echarts>
                </div>
                <div class="canvas-item" v-if="age.data">
                    <echarts :echarts="age.data"></echarts>
                </div>
                <div class="canvas-item" v-if="race.data">
                    <echarts :echarts="race.data"></echarts>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent, reactive, ref, onBeforeUnmount } from 'vue'
import { rj_getModel } from '@/serve/index'
import { formatTime } from '@/utils/formatTime.js'
import EventBus from '@/utils/mitt'
import echarts from '@/components/rj-echarts.vue'
export default defineComponent({
    components:{
        echarts
    },
    setup(){
        //是否显示canvas
        const showCanvas = ref(false)
        // 亮度echarts图
        let brightness = reactive({})
        // 色温echarts图
        let colorTemperature = reactive({})
        // 光源echarts图
        let lightSource = reactive({})
        // 模糊度echarts图
        let fuzziness = reactive({})
        // 姿势echarts图
        let posture = reactive({})
        // 距离echarts图
        let distance = reactive({})
        // 眼镜echarts图
        let glasses = reactive({})
        // 妆型echarts图
        let makeupType = reactive({})
        // 表情echarts图
        let expression = reactive({})
        // 性别echarts图
        let sex = reactive({})
        // 年龄echarts图
        let age = reactive({})
        // 人种echarts图
        let race = reactive({})
        // 页面定时器
        let timer = null
        // 定时器获取数据次数
        let timerCount = 0
        const initBrightness = (echartsData) => {
            if(!echartsData || echartsData.value == 0){
                return
            }
            const echartsDataList = echartsData.value
            const brightnessData = []
            const colorList = [
                'rgb(190, 190, 190)',
                'rgb(220, 220, 220)',
                'rgb(240, 240, 240)'
            ]
            const {levels, min, max} = echartsData
            const markAreaData = []
            const dataTips = [
                {
                    dataTip:echartsDataList.map((item, index)=>{
                        const { month, day } = formatTime(item.createdTime)
                        item.score = String(item.score).split('.').length > 1 ? item.score.toFixed(2) : item.score + '.00'
                        brightnessData.push(item.score)
                        return {
                            name:`${month}月${day}日第${item.times}次`,
                            showTitle:`${item.score}`
                        }
                    })
                }
            ]
            levels.forEach((item, index)=>{
                markAreaData.push([
                    {
                        yAxis:item.start,
                        name:item.label,
                        itemStyle:{
                            color:colorList[index]
                        },
                    },
                    {
                        yAxis:item.end
                    }
                ])
            })
            const len = brightnessData.length
            brightness.data = {
                minY:min,
                maxY:max,
                dataTips,
                title:'亮度',
                yAxisName:'亮度',
                echartId:'brightness',
                currentTitleValue:brightnessData[len - 1],
                yAxisLabel:{
                    show: true,
                    color: 'rgba(0,0,0,0.9)',
                    formatter: function (value, index) {
                        return value
                    }
                },
                series:[
                    {
                        name:'亮度',
                        type:'line',
                        data:brightnessData,
                        triggerLineEvent:true,
                        symbolSize:8,
                        markArea:{
                            silent:true,
                            data:markAreaData,
                            label:{
                                position:'right'
                            }
                        },
                        markLine:{
                            silent:true,
                            symbolSize:0,
                            data:[
                                {
                                    name:'',
                                    xAxis:brightnessData.length-1,
                                    label:{
                                        formatter:function(params){
                                            return dataTips[0].dataTip[len - 1].name;
                                        },
                                        fontSize:8,
                                    },
                                    lineStyle:{
                                        color: "rgba(210, 216, 253, 0.5)",
                                        width:20,
                                        type:'solid',
                                        opacity:1
                                    }
                                }
                            ]
                        }
                    },
                ]
            }
        }
        const initColorTemperature = (echartsData) => {
            if(!echartsData || echartsData.value == 0){
                return
            }
            const echartsDataList = echartsData.value
            const colorTemperatureData = []
            const colorList = [
                'rgb(228, 202, 168)',
                'rgb(255, 255, 207)',
                'rgb(159, 206, 226)'
            ]
            const {levels, min, max} = echartsData
            const markAreaData = []
            const dataTips = [
                {
                    dataTip:echartsDataList.map((item, index)=>{
                        const { month, day } = formatTime(item.createdTime)
                        item.score = String(item.score).split('.').length > 1 ? item.score.toFixed(2) : item.score + '.00'
                        colorTemperatureData.push(item.score)
                        return {
                            name:`${month}月${day}日第${item.times}次`,
                            showTitle:`${item.score}K`
                        }
                    })
                }
            ]
            levels.forEach((item, index)=>{
                markAreaData.push([
                    {
                        yAxis:item.start,
                        name:item.label,
                        itemStyle:{
                            color:colorList[index]
                        },
                    },
                    {
                        yAxis:item.end
                    }
                ])
            })
            let len = colorTemperatureData.length
            colorTemperature.data = {
                minY:min,
                maxY:max,
                dataTips,
                title:'色温',
                yAxisName:'色温',
                echartId:'colorTemperature',
                currentTitleValue:dataTips[0].dataTip[len - 1].showTitle,
                yAxisLabel:{
                    show: true,
                    color: 'rgba(0,0,0,0.9)',
                    formatter: function (value, index) {
                        return value + 'K'
                    }
                },
                series:[
                    {
                        name:'色温',
                        type:'line',
                        data:colorTemperatureData,
                        triggerLineEvent:true,
                        symbolSize:8,
                        markArea:{
                            silent:true,
                            data:markAreaData,
                            label:{
                                position:'right'
                            }
                        },
                        markLine:{
                            silent:true,
                            symbolSize:0,
                            data:[
                                {
                                    name:'',
                                    xAxis:colorTemperatureData.length-1,
                                    label:{
                                        formatter:function(params){
                                            return dataTips[0].dataTip[len - 1].name;
                                        },
                                        fontSize:8,
                                    },
                                    lineStyle:{
                                        color: "rgba(210, 216, 253, 0.5)",
                                        width:20,
                                        type:'solid',
                                        opacity:1
                                    }
                                }
                            ]
                        }
                    },
                ]
            }
        }
        const initLightSource = (echartsData) => {
            if(!echartsData || echartsData.value == 0){
                return
            }
            const echartsDataList = echartsData.value
            const lightSourceData = []
            const colorList = [
                'rgb(228, 202, 168)',
                'rgb(255, 255, 207)',
                'rgb(159, 206, 226)'
            ]
            const {levels, min, max} = echartsData
            const markAreaData = []
            const dataTips = [
                {
                    dataTip:echartsDataList.map((item, index)=>{
                        const { month, day } = formatTime(item.createdTime)
                        lightSourceData.push(item.score)
                        return {
                            name:`${month}月${day}日第${item.times}次`,
                            showTitle:levels.filter(levelsItem=>levelsItem.start <= item.score && levelsItem.end >= item.score)[0].label
                        }
                    })
                }
            ]
            levels.forEach((item, index)=>{
                markAreaData.push([
                    {
                        yAxis:item.start,
                        name:item.label,
                        itemStyle:{
                            color:colorList[index]
                        },
                    },
                    {
                        yAxis:item.end
                    }
                ])
            })
            let len = lightSourceData.length
            lightSource.data = {
                minY:min,
                maxY:max,
                maxInterval:0.5,
                dataTips,
                title:'光源方向',
                yAxisName:'光源',
                echartId:'lightSource',
                currentTitleValue:dataTips[0].dataTip[len - 1].showTitle,
                yAxisLabel:{
                    show: true,
                    color: 'rgba(0,0,0,0.9)',
                    shadowOffsetY: 10,
                    formatter: function (value, index) {
                        if(value == 0.5){
                            return '右下'
                        }else if(value == 1.5){
                            return '中下'
                        }else if(value == 2.5){
                            return '左下'
                        }else if(value == 3.5){
                            return '右中'
                        }else if(value == 4.5){
                            return '中中'
                        }else if(value == 5.5){
                            return '左中'
                        }else if(value == 6.5){
                            return '右上'
                        }else if(value == 7.5){
                            return '中上'
                        }else if(value == 8.5){
                            return '左上'
                        }
                        return ''
                    }
                },
                series:[
                    {
                        name:'光源方向',
                        type:'line',
                        data:lightSourceData,
                        triggerLineEvent:true,
                        symbolSize:8,
                        markArea:{
                            silent:true,
                            data:[
                                [
                                    {
                                        yAxis:0,
                                        name:'下',
                                        itemStyle:{
                                            color:'rgb(228, 202, 168)'
                                        },
                                    },
                                    {
                                        yAxis:3
                                    }
                                ],
                                [
                                    {
                                        yAxis:3,
                                        name:'中',
                                        itemStyle:{
                                            color:'rgb(255, 255, 207)'
                                        },
                                    },
                                    {
                                        yAxis:6
                                    }
                                ],
                                [
                                    {
                                        yAxis:6,
                                        name:'上',
                                        itemStyle:{
                                            color:'rgb(159, 206, 226)'
                                        },
                                    },
                                    {
                                        yAxis:9
                                    }
                                ],
                            ],
                            label:{
                                position:'right'
                            }
                        },
                        markLine:{
                            silent:true,
                            symbolSize:0,
                            data:[
                                {
                                    name:'',
                                    xAxis:lightSourceData.length-1,
                                    label:{
                                        formatter:function(params){
                                            return dataTips[0].dataTip[len - 1].name;
                                        },
                                        fontSize:8,
                                    },
                                    lineStyle:{
                                        color: "rgba(210, 216, 253, 0.5)",
                                        width:20,
                                        type:'solid',
                                        opacity:1
                                    }
                                }
                            ]
                        }
                    },
                ]
            }
        }
        const initFuzziness = (echartsData) => {
            if(!echartsData || echartsData.value == 0){
                return
            }
            const echartsDataList = echartsData.value
            const fuzzinessData = []
            const colorList = [
                'rgb(228, 202, 168)',
                'rgb(255, 255, 207)',
                'rgb(159, 206, 226)'
            ]
            const {levels, min, max} = echartsData
            const markAreaData = []
            const dataTips = [
                {
                    dataTip:echartsDataList.map((item, index)=>{
                        const { month, day } = formatTime(item.createdTime)
                        item.score = String(item.score).split('.').length > 1 ? item.score.toFixed(2) : item.score + '.00'
                        fuzzinessData.push(item.score)
                        return {
                            name:`${month}月${day}日第${item.times}次`,
                            showTitle:`${item.score}`
                        }
                    })
                }
            ]
            levels.forEach((item, index)=>{
                markAreaData.push([
                    {
                        yAxis:item.start,
                        name:item.label,
                        itemStyle:{
                            color:colorList[index]
                        },
                    },
                    {
                        yAxis:item.end
                    }
                ])
            })
            let len = fuzzinessData.length
            fuzziness.data = {
                minY:min,
                maxY:max,
                dataTips,
                title:'模糊度',
                yAxisName:'模糊度',
                echartId:'fuzziness',
                currentTitleValue:dataTips[0].dataTip[len - 1].showTitle,
                yAxisLabel:{
                    show: true,
                    color: 'rgba(0,0,0,0.9)',
                    formatter: function (value, index) {
                        if(value == 1){
                            return '1.00'
                        }
                        return value.toFixed(2)
                    }
                },
                series:[
                    {
                        name:'模糊度',
                        type:'line',
                        data:fuzzinessData,
                        triggerLineEvent:true,
                        symbolSize:8,
                        markArea:{
                            silent:true,
                            data:markAreaData,
                            label:{
                                position:'right'
                            }
                        },
                        markLine:{
                            silent:true,
                            symbolSize:0,
                            data:[
                                {
                                    name:'',
                                    xAxis:fuzzinessData.length-1,
                                    label:{
                                        formatter:function(params){
                                            return dataTips[0].dataTip[len - 1].name;
                                        },
                                        fontSize:8,
                                    },
                                    lineStyle:{
                                        color: "rgba(210, 216, 253, 0.5)",
                                        width:20,
                                        type:'solid',
                                        opacity:1
                                    }
                                }
                            ]
                        }
                    },
                ]
            }
        }
        const initPosture = (echartsData) => {
            if(!echartsData || echartsData.value == 0){
                return
            }
            const echartsDataList = echartsData.value
            const pitch = []
            const yaw = []
            const roll = []
            const {min, max} = echartsData
            const dataTips = [
                {
                    dataTip:echartsDataList.map((item, index)=>{
                        const { month, day } = formatTime(item.createdTime)
                        item.pitch = String(item.pitch).split('.').length > 1 ? item.pitch.toFixed(2) : item.pitch + '.00'
                        item.yaw = String(item.yaw).split('.').length > 1 ? item.yaw.toFixed(2) : item.yaw + '.00'
                        item.roll = String(item.roll).split('.').length > 1 ? item.roll.toFixed(2) : item.roll + '.00'
                        pitch.push(item.pitch)
                        yaw.push(item.yaw)
                        roll.push(item.roll)
                        return {
                            name:`${month}月${day}日第${item.times}次`,
                            showTitle:`pitch:${item.pitch}，yaw:${item.yaw}，roll:${item.roll}`
                        }
                    })
                }
            ]
            let len = pitch.length
            posture.data = {
                minY:min,
                maxY:max,
                dataTips,
                title:'姿势',
                yAxisName:'角度',
                echartId:'posture',
                legend:{
                    show: true,
                },
                currentTitleValue:dataTips[0].dataTip[len - 1].showTitle,
                yAxisLabel:{
                    show: true,
                    color: 'rgba(0,0,0,0.9)',
                    formatter: function (value, index) {
                        if(value == 1){
                            return '1.00'
                        }
                        return value.toFixed(2)
                    }
                },
                series:[
                    {
                        name:'pitch',
                        type:'line',
                        data:pitch,
                        triggerLineEvent:true,
                        symbolSize:8,
                        markLine:{
                            silent:true,
                            symbolSize:0,
                            data:[
                                {
                                    name:'',
                                    xAxis:len-1,
                                    label:{
                                        formatter:function(params){
                                            return dataTips[0].dataTip[len - 1].name;
                                        },
                                        fontSize:8,
                                    },
                                    lineStyle:{
                                        color: "rgba(210, 216, 253, 0.5)",
                                        width:20,
                                        type:'solid',
                                        opacity:1
                                    }
                                }
                            ]
                        }
                    },
                    {
                        name:'yaw',
                        type:'line',
                        data:yaw,
                        triggerLineEvent:true,
                        symbolSize:8,
                        markLine:{
                            silent:true,
                            symbolSize:0,
                            data:[
                                {
                                    name:'',
                                    xAxis:len-1,
                                    label:{
                                        formatter:function(params){
                                            return dataTips[0].dataTip[len - 1].name;
                                        },
                                        fontSize:8,
                                    },
                                    lineStyle:{
                                        color: "rgba(210, 216, 253, 0.5)",
                                        width:20,
                                        type:'solid',
                                        opacity:1
                                    }
                                }
                            ]
                        }
                    },
                    {
                        name:'roll',
                        type:'line',
                        data:roll,
                        triggerLineEvent:true,
                        symbolSize:8,
                        markLine:{
                            silent:true,
                            symbolSize:0,
                            data:[
                                {
                                    name:'',
                                    xAxis:len-1,
                                    label:{
                                        formatter:function(params){
                                            return dataTips[0].dataTip[len - 1].name;
                                        },
                                        fontSize:8,
                                    },
                                    lineStyle:{
                                        color: "rgba(210, 216, 253, 0.5)",
                                        width:20,
                                        type:'solid',
                                        opacity:1
                                    }
                                }
                            ]
                        }
                    },
                ]
            }
        }
        const initDistance = (echartsData) => {
            if(!echartsData || echartsData.value == 0){
                return
            }
            const echartsDataList = echartsData.value
            const distanceData = []
            const colorList = [
                'rgb(190, 190, 190)',
                'rgb(220, 220, 220)',
                'rgb(240, 240, 240)'
            ]
            const {levels, min, max} = echartsData
            const markAreaData = []
            const dataTips = [
                {
                    dataTip:echartsDataList.map((item, index)=>{
                        const { month, day } = formatTime(item.createdTime)
                        item.score = String(item.score).split('.').length > 1 ? item.score.toFixed(2) : item.score + '.00'
                        distanceData.push(item.score)
                        return {
                            name:`${month}月${day}日第${item.times}次`,
                            showTitle:`${item.score}cm`
                        }
                    })
                }
            ]
            levels.forEach((item, index)=>{
                markAreaData.push([
                    {
                        yAxis:item.start,
                        name:item.label,
                        itemStyle:{
                            color:colorList[index]
                        },
                    },
                    {
                        yAxis:item.end
                    }
                ])
            })
            let len = distanceData.length
            distance.data = {
                minY:min,
                maxY:max,
                dataTips,
                title:'距离',
                yAxisName:'距离cm',
                echartId:'distance',
                currentTitleValue:dataTips[0].dataTip[len - 1].showTitle,
                yAxisLabel:{
                    show: true,
                    color: 'rgba(0,0,0,0.9)',
                    formatter: function (value, index) {
                        return value
                    }
                },
                series:[
                    {
                        name:'距离',
                        type:'line',
                        data:distanceData,
                        triggerLineEvent:true,
                        symbolSize:8,
                        markLine:{
                            silent:true,
                            symbolSize:0,
                            data:[
                                {
                                    name:'',
                                    xAxis:len-1,
                                    label:{
                                        formatter:function(params){
                                            return dataTips[0].dataTip[len - 1].name;
                                        },
                                        fontSize:8,
                                    },
                                    lineStyle:{
                                        color: "rgba(210, 216, 253, 0.5)",
                                        width:20,
                                        type:'solid',
                                        opacity:1
                                    }
                                }
                            ]
                        },
                        markArea:{
                            silent:true,
                            data:markAreaData,
                            label:{
                                position:'right'
                            }
                        },
                    }
                ]
            }
        }
        const initGlasses = (echartsData) => {
            if(!echartsData || echartsData.value == 0){
                return
            }
            const echartsDataList = echartsData.value
            const glassesData = []
            const colorList = [
                'rgb(190, 190, 190)',
                'rgb(220, 220, 220)',
                'rgb(240, 240, 240)'
            ]
            const {levels, min, max} = echartsData
            const markAreaData = []
            const dataTips = [
                {
                    dataTip:echartsDataList.map((item, index)=>{
                        const { month, day } = formatTime(item.createdTime)
                        glassesData.push(item.score)
                        let showTitle = levels.filter(levelsItem=>levelsItem.start <= item.score && levelsItem.end >= item.score)[0].label
                        return {
                            name:`${month}月${day}日第${item.times}次`,
                            showTitle:`${showTitle}`
                        }
                    })
                }
            ]
            levels.forEach((item, index)=>{
                markAreaData.push([
                    {
                        yAxis:item.start,
                        name:item.label,
                        itemStyle:{
                            color:colorList[index]
                        },
                    },
                    {
                        yAxis:item.end
                    }
                ])
            })
            let len = glassesData.length
            glasses.data = {
                minY:min,
                maxY:max,
                dataTips,
                title:'眼镜',
                yAxisName:'眼镜',
                echartId:'glasses',
                currentTitleValue:dataTips[0].dataTip[len - 1].showTitle,
                grid:{
                    left:'15%'
                },
                yAxisLabel:{
                    show: true,
                    color: 'rgba(0,0,0,0.9)',
                    formatter: function (value, index) {
                        return ''
                    }
                },
                series:[
                    {
                        name:'眼镜',
                        type:'line',
                        data:glassesData,
                        triggerLineEvent:true,
                        symbolSize:8,
                        markLine:{
                            silent:true,
                            symbolSize:0,
                            data:[
                                {
                                    name:'',
                                    xAxis:len-1,
                                    label:{
                                        formatter:function(params){
                                            return dataTips[0].dataTip[len - 1].name;
                                        },
                                        fontSize:8,
                                    },
                                    lineStyle:{
                                        color: "rgba(210, 216, 253, 0.5)",
                                        width:20,
                                        type:'solid',
                                        opacity:1
                                    }
                                }
                            ]
                        },
                        markArea:{
                            silent:true,
                            data:markAreaData,
                            label:{
                                position:'left'
                            }
                        },
                    }
                ]
            }
        }
        const initMakeupType = (echartsData) => {
            if(!echartsData || echartsData.value == 0){
                return
            }
            const echartsDataList = echartsData.value
            const makeupTypeData = []
            const colorList = [
                'rgb(240, 240, 240)',
                'rgb(220, 220, 220)',
                'rgb(190, 190, 190)',
            ]
            const {levels, min, max} = echartsData
            const markAreaData = []
            const dataTips = [
                {
                    dataTip:echartsDataList.map((item, index)=>{
                        const { month, day } = formatTime(item.createdTime)
                        makeupTypeData.push(item.score)
                        let showTitle = levels.filter(levelsItem=>levelsItem.start <= item.score && levelsItem.end >= item.score)[0].label
                        return {
                            name:`${month}月${day}日第${item.times}次`,
                            showTitle:`${showTitle}`
                        }
                    })
                }
            ]
            levels.forEach((item, index)=>{
                markAreaData.push([
                    {
                        yAxis:item.start,
                        name:item.label,
                        itemStyle:{
                            color:colorList[index]
                        },
                    },
                    {
                        yAxis:item.end
                    }
                ])
            })
            let len = makeupTypeData.length
            makeupType.data = {
                minY:min,
                maxY:max,
                dataTips,
                minInterval:1,
                title:'妆型',
                yAxisName:'妆型',
                echartId:'makeupType',
                currentTitleValue:dataTips[0].dataTip[len - 1].showTitle,
                grid:{
                    left:'10%'
                },
                yAxisLabel:{
                    show: true,
                    color: 'rgba(0,0,0,0.9)',
                    formatter: function (value, index) {
                        return ''
                    }
                },
                series:[
                    {
                        name:'妆型',
                        type:'line',
                        data:makeupTypeData,
                        triggerLineEvent:true,
                        symbolSize:8,
                        markLine:{
                            silent:true,
                            symbolSize:0,
                            data:[
                                {
                                    name:'',
                                    xAxis:len-1,
                                    label:{
                                        formatter:function(params){
                                            return dataTips[0].dataTip[len - 1].name;
                                        },
                                        fontSize:8,
                                    },
                                    lineStyle:{
                                        color: "rgba(210, 216, 253, 0.5)",
                                        width:20,
                                        type:'solid',
                                        opacity:1
                                    }
                                }
                            ]
                        },
                        markArea:{
                            silent:true,
                            data:markAreaData,
                            label:{
                                position:'left'
                            }
                        },
                    }
                ]
            }
        }
        const initExpression = (echartsData) => {
            if(!echartsData || echartsData.value == 0){
                return
            }
            const echartsDataList = echartsData.value
            const expressionData = []
            const colorList = [
                'rgba(0, 47, 93, 0.6)',
                'rgba(1, 80, 158, 0.6)',
                'rgba(0, 157, 211, 0.6)',
                'rgba(255, 255, 255, 1)',
                'rgba(241, 208, 212, 0.6)',
                'rgba(232, 52, 40, 0.6)',
                'rgba(229, 1, 58, 0.6)'
            ]
            const {levels, min, max} = echartsData
            const markAreaData = []
            const dataTips = [
                {
                    dataTip:echartsDataList.map((item, index)=>{
                        const { month, day } = formatTime(item.createdTime)
                        expressionData.push(item.score)
                        let showTitle = levels.filter(levelsItem=>levelsItem.start <= item.score && levelsItem.end >= item.score)[0].label
                        return {
                            name:`${month}月${day}日第${item.times}次`,
                            showTitle:`${showTitle}`
                        }
                    })
                }
            ]
            levels.forEach((item, index)=>{
                markAreaData.push([
                    {
                        yAxis:item.start,
                        name:item.label,
                        itemStyle:{
                            color:colorList[index]
                        },
                    },
                    {
                        yAxis:item.end
                    }
                ])
            })
            let len = expressionData.length
            expression.data = {
                minY:min,
                maxY:max,
                dataTips,
                title:'表情',
                yAxisName:'表情',
                echartId:'expression',
                currentTitleValue:dataTips[0].dataTip[len - 1].showTitle,
                yAxisLabel:{
                    show: true,
                    color: 'rgba(0,0,0,0.9)',
                    formatter: function (value, index) {
                        return ''
                    }
                },
                grid:{
                    left:'10%',
                    right:'10%',
                },
                series:[
                    {
                        name:'表情',
                        type:'line',
                        data:expressionData,
                        triggerLineEvent:true,
                        symbolSize:8,
                        markArea:{
                            silent:true,
                            data:markAreaData,
                            label:{
                                position:'left'
                            }
                        },
                        markLine:{
                            silent:true,
                            symbolSize:0,
                            data:[
                                {
                                    name:'',
                                    xAxis:len-1,
                                    label:{
                                        formatter:function(params){
                                            return dataTips[0].dataTip[len - 1].name;
                                        },
                                        fontSize:8,
                                    },
                                    lineStyle:{
                                        color: "rgba(210, 216, 253, 0.5)",
                                        width:20,
                                        type:'solid',
                                        opacity:1
                                    }
                                }
                            ]
                        }
                    }
                ]
            }
        }
        const initSex = (echartsData) => {
            if(!echartsData || echartsData.value == 0){
                return
            }
            const echartsDataList = echartsData.value
            const sexData = []
            const colorList = [
                'rgb(190, 190, 190)',
                'rgb(220, 220, 220)'
            ]
            const {levels, min, max} = echartsData
            const markAreaData = []
            const dataTips = [
                {
                    dataTip:echartsDataList.map((item, index)=>{
                        const { month, day } = formatTime(item.createdTime)
                        sexData.push(item.score)
                        let showTitle = levels.filter(levelsItem=>levelsItem.start <= item.score && levelsItem.end >= item.score)[0].label
                        return {
                            name:`${month}月${day}日第${item.times}次`,
                            showTitle:`${showTitle}`
                        }
                    })
                }
            ]
            levels.forEach((item, index)=>{
                markAreaData.push([
                    {
                        yAxis:item.start,
                        name:item.label,
                        itemStyle:{
                            color:colorList[index]
                        },
                    },
                    {
                        yAxis:item.end
                    }
                ])
            })
            let len = sexData.length
            sex.data = {
                minY:min,
                maxY:max,
                minInterval:1,
                dataTips,
                title:'性别',
                yAxisName:'性别',
                echartId:'sex',
                currentTitleValue:dataTips[0].dataTip[len - 1].showTitle,
                yAxisLabel:{
                    show: true,
                    color: 'rgba(0,0,0,0.9)',
                    formatter: function (value, index) {
                        return ''
                    }
                },
                series:[
                    {
                        name:'性别',
                        type:'line',
                        data:sexData,
                        triggerLineEvent:true,
                        symbolSize:8,
                        markArea:{
                            silent:true,
                            data:markAreaData,
                            label:{
                                position:'left'
                            }
                        },
                        markLine:{
                            silent:true,
                            symbolSize:0,
                            data:[
                                {
                                    name:'',
                                    xAxis:sexData.length-1,
                                    label:{
                                        formatter:function(params){
                                            return dataTips[0].dataTip[len - 1].name;
                                        },
                                        fontSize:8,
                                    },
                                    lineStyle:{
                                        color: "rgba(210, 216, 253, 0.5)",
                                        width:20,
                                        type:'solid',
                                        opacity:1
                                    }
                                }
                            ]
                        }
                    },
                ]
            }
        }
        const initAge = (echartsData) => {
            if(!echartsData || echartsData.value == 0){
                return
            }
            const echartsDataList = echartsData.value
            const ageData = []
            const colorList = [
                'rgb(190, 190, 190)',
                'rgb(190, 203, 163)',
                'rgb(245, 218, 176)',
                'rgb(255, 255, 255)',
                'rgb(241, 209, 212)'
            ]
            const {levels, min, max} = echartsData
            const markAreaData = []
            const dataTips = [
                {
                    dataTip:echartsDataList.map((item, index)=>{
                        const { month, day } = formatTime(item.createdTime)
                        ageData.push(item.score)
                        let showTitle = levels.filter(levelsItem=>levelsItem.start <= item.score && levelsItem.end >= item.score)[0].label
                        return {
                            name:`${month}月${day}日第${item.times}次`,
                            showTitle:`${showTitle}`
                        }
                    })
                }
            ]
            levels.forEach((item, index)=>{
                markAreaData.push([
                    {
                        yAxis:item.start,
                        name:item.label,
                        itemStyle:{
                            color:colorList[index]
                        },
                    },
                    {
                        yAxis:item.end
                    }
                ])
            })
            let len = ageData.length
            age.data = {
                minY:min,
                maxY:max,
                minInterval:1,
                dataTips,
                grid:{
                    left:'10%',
                    right:'10%'
                },
                title:'年龄',
                yAxisName:'年龄',
                echartId:'age',
                currentTitleValue:dataTips[0].dataTip[len - 1].showTitle,
                yAxisLabel:{
                    show: true,
                    color: 'rgba(0,0,0,0.9)',
                    formatter: function (value, index) {
                        return ''
                    }
                },
                series:[
                    {
                        name:'年龄',
                        type:'line',
                        data:ageData,
                        triggerLineEvent:true,
                        symbolSize:8,
                        markArea:{
                            silent:true,
                            data:markAreaData,
                            label:{
                                position:'left'
                            }
                        },
                        markLine:{
                            silent:true,
                            symbolSize:0,
                            data:[
                                {
                                    name:'',
                                    xAxis:ageData.length-1,
                                    label:{
                                        formatter:function(params){
                                            return dataTips[0].dataTip[len - 1].name;
                                        },
                                        fontSize:8,
                                    },
                                    lineStyle:{
                                        color: "rgba(210, 216, 253, 0.5)",
                                        width:20,
                                        type:'solid',
                                        opacity:1
                                    }
                                }
                            ]
                        }
                    },
                ]
            }
        }
        const initRace = (echartsData) => {
            if(!echartsData || echartsData.value == 0){
                return
            }
            const echartsDataList = echartsData.value
            const raceData = []
            const colorList = [
                'rgb(190, 190, 190)',
                'rgb(190, 203, 163)',
                'rgb(245, 218, 176)',
                'rgb(255, 255, 255)',
                'rgb(241, 209, 212)'
            ]
            const {levels, min, max} = echartsData
            const markAreaData = []
            const dataTips = [
                {
                    dataTip:echartsDataList.map((item, index)=>{
                        const { month, day } = formatTime(item.createdTime)
                        raceData.push(item.score)
                        let showTitle = levels.filter(levelsItem=>levelsItem.start <= item.score && levelsItem.end >= item.score)[0].label
                        return {
                            name:`${month}月${day}日第${item.times}次`,
                            showTitle:`${showTitle}`
                        }
                    })
                }
            ]
            levels.forEach((item, index)=>{
                markAreaData.push([
                    {
                        yAxis:item.start,
                        name:item.label,
                        itemStyle:{
                            color:colorList[index]
                        },
                    },
                    {
                        yAxis:item.end
                    }
                ])
            })
            let len = raceData.length
            race.data = {
                minY:min,
                maxY:max,
                dataTips,
                grid:{
                    left:'15%',
                    right:'10%'
                },
                title:'人种',
                yAxisName:'人种',
                echartId:'race',
                currentTitleValue:dataTips[0].dataTip[len - 1].showTitle,
                yAxisLabel:{
                    show: true,
                    color: 'rgba(0,0,0,0.9)',
                    formatter: function (value, index) {
                        return ''
                    }
                },
                series:[
                    {
                        name:'人种',
                        type:'line',
                        data:raceData,
                        triggerLineEvent:true,
                        symbolSize:8,
                        markArea:{
                            silent:true,
                            data:markAreaData,
                            label:{
                                position:'left'
                            }
                        },
                        markLine:{
                            silent:true,
                            symbolSize:0,
                            data:[
                                {
                                    name:'',
                                    xAxis:raceData.length-1,
                                    label:{
                                        formatter:function(params){
                                            return dataTips[0].dataTip[len - 1].name;
                                        },
                                        fontSize:8,
                                    },
                                    lineStyle:{
                                        color: "rgba(210, 216, 253, 0.5)",
                                        width:20,
                                        type:'solid',
                                        opacity:1
                                    }
                                }
                            ]
                        }
                    },
                ]
            }
        }
        const initCharts = (flag = 'init') => {
            rj_getModel('beijing').then(res=>{
                if(res.data === null || res.regionVos.length == 0){
                    showCanvas.value = false
                    if(flag === 'newTask'){
                        timer = setTimeout(()=>{
                            timerCount++
                            if(timerCount <= 40){
                                initCharts('newTask')
                            }else{
                                timerCount = 0
                            }
                        },1500)
                    }
                    return
                }
                showCanvas.value = true
                if(res.finish == false){
                    timer = setTimeout(()=>{
                        timerCount++
                        if(timerCount <= 40){
                            initCharts('newTask')
                        }else{
                            timerCount = 0
                        }
                    },1500)
                }else if(res.finish  == true){
                    timerCount = 0
                }
                const featureVos = res.regionVos[0].featureVos
                const environment = featureVos.find(item=>item.type == 'environment')
                if(environment){
                    const liangdu = environment.featureLists.find(item=>item.key == 'liangdu')
                    const sewen = environment.featureLists.find(item=>item.key == 'sewen')
                    const guangyuanfangxiang = environment.featureLists.find(item=>item.key == 'guangyuanfangxiang')
                    const mohudu = environment.featureLists.find(item=>item.key == 'mohudu')
                    initBrightness(liangdu)
                    initColorTemperature(sewen)
                    initLightSource(guangyuanfangxiang)
                    initFuzziness(mohudu)
                }
                const activity = featureVos.find(item=>item.type == 'activity')
                if(activity){
                    const zishi = activity.featureLists.find(item=>item.key == 'zishi')
                    const juli = activity.featureLists.find(item=>item.key == 'juli')
                    const yanjing = activity.featureLists.find(item=>item.key == 'yanjing')
                    const huazhuang = activity.featureLists.find(item=>item.key == 'huazhuang')
                    const biaoqing = activity.featureLists.find(item=>item.key == 'biaoqing')
                    initPosture(zishi)
                    initDistance(juli)
                    initGlasses(yanjing)
                    initMakeupType(huazhuang)
                    initExpression(biaoqing)
                }
                const crowd = featureVos.find(item=>item.type == 'crowd')
                if(crowd){
                    const xingbie = crowd.featureLists.find(item=>item.key == 'xingbie')
                    const nianling = crowd.featureLists.find(item=>item.key == 'nianling')
                    const renzhong = crowd.featureLists.find(item=>item.key == 'renzhong')
                    initSex(xingbie)
                    initAge(nianling)
                    initRace(renzhong)
                }
            })
        }
        initCharts()
        EventBus.on('newTask', (res)=>{
            initCharts('newTask')
        })
        onBeforeUnmount(()=>{
            EventBus.off('newTask')
            timer && clearTimeout(timer)
            timerCount = 41
        })
        return {
            showCanvas,
            brightness,
            colorTemperature,
            lightSource,
            fuzziness,
            posture,
            distance,
            glasses,
            makeupType,
            expression,
            sex,
            age,
            race
        }
    }
})
</script>

<style lang="scss" scoped>
    .influence-factor{
        width: 1600px;
        margin: 0 auto;
        margin-top: 16px;
        position: relative;
        left: -8px;
        .canvas-box{
            width: 1600px;
            margin-bottom: 16px;
            .min-tit{
                font-size: 20px;
                font-weight: 550;
            }
            .canvas-content{
                margin-top: 8px; 
                border-radius: 8px;
                border: 1px solid #ccc;
                box-sizing: content-box;
                padding-bottom: 16px;
                display: flex;
                flex-wrap: wrap;
                .canvas-item{
                    width: 399px;
                    height: auto;
                    margin-top: 16px;
                }
            }
        }
    }
</style>