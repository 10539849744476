<template>
    <div class="page-line" :class="showChildren?'children-nav':'no-children-nav'"></div>
    <div class="page-header">
        <div class="header-title">
            <div class="title">人脸微变化模型专业版</div>
            <div class="header-right">
                <div v-if="updateTime" class="time">更新时间:{{updateTime}}</div>
                <div class="login" v-if="keyClock.data.ready">
                    <el-button v-if="!keyClock.data.authenticated" @click="$keycloak.login" type="primary">登录</el-button>
                    <el-dropdown v-else>
                        <div class="avatar-box">
                            <img :src="$keycloak.tokenParsed.avatar">
                        </div>
                        <template #dropdown>
                            <el-dropdown-menu>
                                <el-dropdown-item @click="$keycloak.logoutFn" @mouseup="logout">退出登录</el-dropdown-item>
                            </el-dropdown-menu>
                        </template>
                    </el-dropdown>
                </div>
                <div class="upload">
                    <el-button v-if="!keyClock.data.authenticated" :disabled="true" type="info">上传</el-button>
                    <el-dropdown v-else>
                        <span class="el-dropdown-link">
                            <el-button type="info">上传</el-button>
                        </span>
                        <template #dropdown>
                            <el-dropdown-menu>
                                <el-dropdown-item @click="photograph">拍照</el-dropdown-item>
                                <el-dropdown-item>
                                    <span @click="choiceImg">上传</span>
                                    <input v-show="false" id="filElem" type="file" @change="getFile">
                                </el-dropdown-item>
                            </el-dropdown-menu>
                        </template>
                    </el-dropdown>
                </div>
            </div>
        </div>
        <div class="header-nav">
            <div class="nav">
                <div class="router-item" :class="currentRouterName == routeItem.name ? 'active' : ''" v-for="routeItem in routerList.value" :key="routeItem.name">
                    <router-link :to="routeItem.path">
                        <span>{{routeItem.meta.title}}</span>
                    </router-link>
                </div>
            </div>
        </div>
        <div class="header-children-nav" v-if="showChildren">
            <div class="nav">
                <div class="router-item" :class="currentRouterChildrenName == routeItem.name ? 'active' : ''" v-for="routeItem in routerChildrenList.value" :key="routeItem.name">
                    <router-link :to="routeItem.path">
                        <span>{{routeItem.meta.title}}</span>
                    </router-link>
                </div>
            </div>
        </div>
        <div class="video-model" @click.self="hiddenVideo" v-if="showVideo">
            <div class="photo-box">
                <div class="video-box">
                    <video id="video" class="video" width="480" height="320" autoplay></video>
                    <el-button type="primary" @click="takePhoto">拍摄</el-button>
                </div>
                <div class="canvas-box" v-if="showImage">
                    <canvas id="canvas" width="480" height="320" style="display:none"></canvas>
                    <img id="img" width="480" height="320">
                    <el-button type="primary" @click="uploadFile">上传</el-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent, reactive, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { rj_uploadImage, rj_createModel } from '@/serve/index'
import { formatTime } from '@/utils/formatTime.js'
import { ElMessage } from 'element-plus'
import EventBus from '@/utils/mitt'
export default defineComponent({
    setup(){
        const router = useRouter()
        const route = useRoute()
        const routerList = reactive([])
        const currentRouterName = ref('')
        const showChildren = ref(false)
        const routerChildrenList = reactive([])
        const currentRouterChildrenName = ref('')
        const keyClock = reactive({
            data:{},
        })
        const isLogin = ref(false)
        const showVideo = ref(false)
        const showImage = ref(false)
        const updateTime = ref('')
        let video = ''
        let file = ''
        /**
         * 设置当前路由
         */
        const setCurrentRouter =(route)=>{
            let routerName = route.name
            if(routerName == 'facialFeatures'){
                showChildren.value = true
                routerChildrenList.value = route.matched[0].children
                currentRouterChildrenName.value = route.matched[0].children[0].name
            }else{
                showChildren.value = false
            }
            if(['leftEye','rightEye','leftEyebrow','rightEyebrow','nose','lips'].includes(routerName)){
                let routes = router.options.routes
                let currentRouter = routes.find(item=>item.name == 'facialFeatures')
                routerChildrenList.value = currentRouter.children
                currentRouterChildrenName.value = routerName
                routerName = 'facialFeatures'
                showChildren.value = true
            }
            currentRouterName.value = routerName
        }

        const setKerClock = () => {
            let timer = setInterval(()=>{
                const keycloakInfo = JSON.parse(sessionStorage.getItem('keycloakInfo'))
                if(keycloakInfo && keycloakInfo.ready){
                    keyClock.data = keycloakInfo
                    sessionStorage.removeItem('keycloakInfo')
                    clearInterval(timer)
                }
            },100)
        }
        setKerClock()

        /**
         * 退出登录
         */
        const logout = () => {
            localStorage.removeItem('token')
            localStorage.removeItem('refreshToken')
            sessionStorage.removeItem('authenticated')
        }

        const getUserMedia = (constraints, success, error) => {
            if (navigator.mediaDevices.getUserMedia) {
                //最新的标准API
                navigator.mediaDevices.getUserMedia(constraints).then(success).catch(error);
            } else if (navigator.webkitGetUserMedia) {
                //webkit核心浏览器
                navigator.webkitGetUserMedia(constraints,success, error)
            } else if (navigator.mozGetUserMedia) {
                //firfox浏览器
                navigator.mozGetUserMedia(constraints, success, error);
            } else if (navigator.getUserMedia) {
                //旧版API
                navigator.getUserMedia(constraints, success, error);
            }
        }

        const success = (stream) => {
            showVideo.value = true
            video = document.getElementById('video')
            if(!video){
                setTimeout(()=>{
                    success(stream)
                },100)
                return
            }
            video.srcObject = stream;
            video.play();
        }
 
        const error = (error) => {
            alert('不支持访问用户媒体');
        }

        const photograph = () => {
            if(!navigator.mediaDevices){
                alert('不支持访问用户媒体');
                return
            }
            if (navigator.mediaDevices.getUserMedia || navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia) {
                getUserMedia({video : {width: 480, height: 320}}, success, error);
            } else {
                alert('不支持访问用户媒体');
            }
        }

        const takePhoto = () => {
            showImage.value = true
            let canvas = document.getElementById('canvas');
            let img = document.getElementById("img");
            if(!canvas || !img){
                setTimeout(()=>{
                    takePhoto()
                },100)
                return
            }
            let context = canvas.getContext('2d');
            context.drawImage(video, 0, 0, 480, 320);
            let image = canvas.toDataURL('image/png');
            img.src = image; 
            function dataURLtoFile(dataurl, filename) {
                let arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
                    bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
                while (n--) {
                    u8arr[n] = bstr.charCodeAt(n);
                }
                file = new File([u8arr], filename, {type: mime});
                return new File([u8arr], filename, {type: mime});
            }
            dataURLtoFile(image, 'data.png')  
        }

        /**
         * 获取所有路由列表
         */
        const setRouterList =()=> {
            const routes = []
            router.options.routes.map(routeItem=>{
                if('name' in routeItem){
                    if(routeItem.name !== 'Login'){
                        routes.push(routeItem)
                    }
                }
            })
            routerList.value = routes
        }

        const choiceImg = ()=> {
            const filElem = document.getElementById('filElem')
            filElem.dispatchEvent(new MouseEvent('click'))
        }

        const getFile = (e)=> {
            if(e.target.files.length == 0){
                return
            }
            file = e.target.files[0]
            if(file.type !== 'image/jpeg' && file.type !== 'image/png' && file.type !== 'image/gif'){
                alert('不是有效的图片文件!');
                return;
            }
            uploadFile()
            let formData = new FormData()
            formData.append('file', file)
        }

        const hiddenVideo = () => {
            showVideo.value = false
            showImage.value = false
            video.srcObject && video.srcObject.getTracks && video.srcObject.getTracks()[0].stop()
        }

        const uploadFile = () => {
            rj_uploadImage(file).then(res=>{
                if(res){
                    ElMessage({
                        showClose: true,
                        message: '图片上传成功',
                        type: 'success',
                    })
                }else{
                    ElMessage({
                        showClose: true,
                        message: '图片上传失败，请重新上传图片',
                        type: 'success',
                    })                    
                }
                rj_createModel(res).then(res=>{
                    if(res){
                        ElMessage({
                            showClose: true,
                            message: '模型创建成功，正在为您获取模型',
                            type: 'success',
                        })
                    }else{
                        ElMessage({
                            showClose: true,
                            message: '模型创建失败，请重新上传图片',
                            type: 'success',
                        })                    
                    }
                    localStorage.setItem('recordId', res)
                    hiddenVideo()
                    EventBus.emit('newTask', res)
                })
            })
        }

        EventBus.on('updateTime', ( res ) => {
            const { year, month, day } = formatTime(res.time)
            updateTime.value = `${year}年${month}月${day}日 第${res.times}次`
        })

        router.afterEach((to, from)=>{
            document.documentElement.scrollTop = 0
            setCurrentRouter(to)
        })
        setRouterList()
        setCurrentRouter(route)
        return {
            routerList,
            currentRouterName,
            showChildren,
            routerChildrenList,
            currentRouterChildrenName,
            showVideo,
            showImage,
            isLogin,
            keyClock,
            updateTime,
            takePhoto,
            photograph,
            choiceImg,
            getFile,
            uploadFile,
            hiddenVideo,
            logout
        }
    }
})
</script>


<style lang="scss" scoped>
    .children-nav{
        height: 120px;
    }
    .no-children-nav{
        height: 80px;
    }
    .page-header{
        width:100%;
        height: auto;
        background: rgb(199, 235, 252);
        box-shadow: 0px 1px 10px 0px rgb(199 235 252 / 8%);
        position: fixed;
        top: 0;
        left: 0;
        z-index: 999;
        .header-title{
            width:100%;
            height: 40px;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            .title{
                font-size:22px;
                font-family:Sans-serif;
                font-weight: 550;
            }
            .header-right{
                width: auto;
                position: absolute;
                right: 16px;
                display: flex;
                align-items: center;
                .time{
                    margin-right: 64px;
                    font-size: 14px;
                }
                .login{
                    margin-right: 16px;
                    .el-button{
                        height: 28px;
                    }
                    .avatar-box{
                        width: 32px;
                        height: 32px;
                        img{
                            width: 32px;
                            height: 32px;
                            border-radius: 50%;
                            cursor: pointer;
                        }
                    }
                }
                .upload{
                    margin-right: 16px;
                    .el-button{
                        height: 28px;
                    }
                }
            }   
        }
        .header-nav, .header-children-nav{
            width:1600px;
            margin: 0 auto;
            height: 40px;
            .nav{
                height: 40px;
                width: auto;
                display: flex;
                .router-item{
                    cursor: pointer;
                    height: 100%;
                    line-height: 40px;
                    width: 120px;
                    text-align: center;
                    box-sizing: border-box;
                    transition: all 0.5s;
                    border: 1px solid rgb(98, 102, 104);
                    border-radius: 4px;
                    a{
                        color:rgba(0, 0, 0, 0.5);
                        font-weight: 540;
                        width: 100%;
                        height: 100%;
                        display: block;
                        span{
                            display: inline-block;
                            box-sizing: border-box;
                            height: 100%;
                            transition: all 0.5s;
                            font-size: 16px;
                        }
                    }
                }
                .router-item:hover, .active{
                    background: #FFF;
                    a{
                        color:rgba(0, 0, 0, 0.9);
                    }
                }
                .active{
                    a{
                        color:rgba(0, 0, 0, 0.9);
                    }
                }
            }
        }
        .video-model{
            width: 100vw;
            height: 100vh;
            position: fixed;
            top: 0;
            left: 0;
            z-index: 999;
            background: rgba(0, 0, 0, 0.5);
            .photo-box{
                position: fixed;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                display: flex;
                .video-box, .canvas-box{
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    margin: 0 16px;
                    .el-button{
                        margin-top: 16px;
                        width: auto;
                        width: 88px;
                    }
                }
            }
        }
    }
</style>