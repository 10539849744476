import axios from 'axios'
import config from '../config'
import { ElMessageBox } from 'element-plus'
import router from '../router/index'
import qs from 'qs'

const baseURL = config.BASE_URL
const service = axios.create({
    baseURL,//请求地址
    timeout: 60000,  // 超时时间 单位是ms
})

service.interceptors.request.use(config => {
    var Authorization = localStorage.getItem('token') || ''
    if(config.url.includes('dataSet')){
        console.log('包括');
    }else{
        config.headers.Authorization = Authorization
    }
    return config;
    }, error => {
        Promise.reject(error);
});

service.interceptors.response.use(response => {
    if(response && response.status) {
      //这里可以做统一的处理
      const data = response.data.data || response.data
      return Promise.resolve(data)
    } 
    }, error => {
    //登录过期处理(刷新token)
    if(error.response.status === 401){
        const response = error.response
        return refreshToken().then(res=>{
            const { tokenType, accessToken, refreshToken } = res.data
            if(tokenType){
                localStorage.setItem('token', tokenType + ' ' + accessToken)
                localStorage.setItem('refreshToken', refreshToken)
                const options = {
                    method: response.config.method,
                    url: baseURL + response.config.url,
                    headers: {
                        Authorization: localStorage.getItem('token')
                    }
                }
                if('data' in response.config && response.config.data !== undefined){
                    options.data = response.config.data
                }
                if('params' in response.config && response.config.params !== undefined){
                    options.params = response.config.params
                }
                return axios(options).then(res=>{
                    const data = res.data.data || res.data
                    return Promise.resolve(data)
                })
            }
        }).catch(error=>{
            ElMessageBox.confirm('您还未登录，请先登录',{
                confirmButtonText:'确定',
                cancelButtonText:'取消'
            })
            .then(() => {
                router.push({
                    name:'home'
                }).then(res=>{
                    localStorage.removeItem('token')
                    window.keycloak.login()
                })
            })
            .catch(() => {
                router.go(-1)
            })
        })
    }else{
        return Promise.reject(error.response);
    }
})

/**
 * 刷新token
 */
function refreshToken() {
    return axios({
        method: "post",
        url: baseURL + '/oauth-login/wechat',
        headers:{
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        data:qs.stringify({
            grant_type:'refresh_token',
            token:localStorage.getItem('refreshToken')
        })
    });
  }

export default service