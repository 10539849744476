const config = {
    // 正式环境
    //BASE_URL:'https://facepro.ragehealth.cn/api/face-pro-prod',
    // 测试环境
    BASE_URL:'https://devfacepro.ragehealth.cn/api/face-pro-test',
    //BASE_URL:'/api',
}

//测试环境  face-pro-test
//生产环境  face-pro-prod

export default config