import service from './request'
import EventBus from '@/utils/mitt'

// 上传图片
export const rj_uploadImage = async (file) => {
    return await service({
        url: '/user/file/upload',
        method:'POST',
        headers:{'Content-Type':'multipart/form-data'},
        data:{
            file
        }
    })
}

// 创建模型
export const rj_createModel = async (fileUrl, type = 'face') => {
    return await service({
        url: `/model/create/face`,
        method:'POST',
        headers: { 
            'Content-Type': 'application/json'
        },
        data:{
            fileUrl
        }
    })
}

// 获取历史记录
export const rj_getModel = async (type) => {
    let recordId =localStorage.getItem('recordId') || ''
    const result = await service({
        url:`/model/${type}`,
        method:'GET',
        params:{
            recordId
        },
        headers:{
            'Content-Type': 'application/x-www-form-urlencoded'
        },
    })
    if(result.data !== null && result.regionVos.length != 0){
        const featureVos = result.regionVos[0].featureVos
        if(featureVos[0] && featureVos[0].featureLists && featureVos[0].featureLists[0] && featureVos[0].featureLists[0].value){
            const value = featureVos[0].featureLists[0].value
            const len = value.length
            const updateObj = value[len - 1]
            EventBus.emit('no-empty')
            if(updateObj){
                EventBus.emit('updateTime', {
                    time:updateObj.createdTime,
                    times:updateObj.times
                })
            }
        }
    }else{
        EventBus.emit('empty')
    }
    return result
}

