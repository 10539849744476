<template>
  <div class="app-content">
    <page-header></page-header>
    <div class="page-content">
        <router-view v-if="loginFlag == 1" v-slot="{ Component }">
            <transition name="fade">
                <component :is="Component" />
            </transition>
        </router-view>
        <div class="no-login" v-if="loginFlag == '0'">
            <img src="@/assets/image/my.png" alt="">
            <div class="no-data-tips">您还没有登录,请先登录</div>
        </div>
        <div class="no-data" v-if="emptyFlag == '0'">
            <img src="@/assets/image/empty.jpeg" alt="">
            <div class="no-data-tips">暂无数据，请点击右上角上传按钮上传图片</div>
        </div>
    </div>
  </div>  
</template>

<script>
import pageHeader from '@/components/page-header.vue'
import { defineComponent, watchEffect, ref} from 'vue'
import EventBus from '@/utils/mitt'
import '@/assets/css/common.css'
export default defineComponent({
    components: {pageHeader},
    created(){
        window.keycloak = this.$keycloak
        watchEffect(()=>{
            if(this.$keycloak.token && this.$keycloak.tokenParsed && this.$keycloak.tokenParsed.typ){
                localStorage.setItem('token',this.$keycloak.tokenParsed.typ + ' ' + this.$keycloak.token)
                localStorage.setItem('refreshToken',this.$keycloak.refreshToken)
                localStorage.setItem('exp', this.$keycloak.tokenParsed.exp)
            } 
        })
        var w = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        sessionStorage.setItem('w', w)
    },
    setup(){
        // 2 等待获取 1 已登录 0未登录
        const loginFlag = ref(2)
        // 2 等待获取 1 有数据 0没有数据
        const emptyFlag = ref(2)
        const setKerClock = () => {
            let timer = setInterval(()=>{
                const keycloakInfo = JSON.parse(sessionStorage.getItem('keycloakInfo'))
                if(keycloakInfo && keycloakInfo.ready){
                    let authenticated = keycloakInfo.authenticated ? '1' : '0'
                    loginFlag.value = authenticated
                    clearInterval(timer)
                }
            },100)
        }
        EventBus.on('empty', res => {
            emptyFlag.value = 0
        })
        EventBus.on('no-empty', res => {
            emptyFlag.value = 1
        })
        setKerClock()
        return {
            loginFlag,
            emptyFlag
        }
    },
})
</script>

<style lang="scss">
    *{
        padding: 0;
        margin: 0;
    }
    body{
        overflow-x: hidden;
        background: #F8F9FF;
    }
    .no-data, .no-login{
        width: 500px;
        position: fixed;
        display: flex;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        flex-wrap: wrap;
        justify-content: center;
        img{
            width: 500px;
            height: 500px;
        }
        .no-data-tips{
            font-size: 18px;
        }
    }
    .fade-enter,
    .fade-leave-to{
        visibility: hidden;
        opacity: 0;
    }
    .fade-enter-active,
    .fade-leave-active{
        transition: opacity 0.05s ease;
    }
    .fade-enter-to,
    .fade-leave{
        visibility: visible;
        opacity: 1;
    }
    #app {
        font-family: Avenir, Helvetica, Arial, sans-serif;
        user-select: none;
        .app-content{
            width: 100vw;
            height: auto;
            position: relative;
            top: 0;
        }
        a{
            text-decoration: none;
            color: none;
        }
        .page-content{
            width: 100%;
            height: auto;
            box-sizing: border-box;
            .no-login{
                text-align: center;
                margin-top: 60px;
                font-size: 18px;
            }
        }
    }
</style>
