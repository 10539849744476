import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import VueKeyCloak from '@dsb-norge/vue-keycloak-js'
import {KeycloakInstance} from "keycloak-js"
import { VueKeycloakInstance } from "@dsb-norge/vue-keycloak-js/dist/types"
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'

createApp(App).use(store).use(ElementPlus).use(router).use(VueKeyCloak, {
    init: {
      // Use 'login-required' to always require authentication
      // If using 'login-required', there is no need for the router guards in router.js
      onLoad: 'check-sso',
      silentCheckSsoRedirectUri: window.location.origin + "/silent-check-sso.html"
    },
    config: {
      url: 'https://id.ragehealth.cn/auth',
      clientId: 'open-front',
      realm: 'Rage',
    },
    onReady (keycloak: KeycloakInstance) {
      const keycloakInfo = {
        authenticated:keycloak.authenticated,
        ready:true
      } 
      sessionStorage.setItem('keycloakInfo', JSON.stringify(keycloakInfo))
    }
}).mount('#app')

declare module '@vue/runtime-core' {
    interface ComponentCustomProperties  {
      $keycloak: VueKeycloakInstance
    }
}
