<template>
    <div class="echarts-box">
        <div class="echarts-header">
            <div class="brief-header">
                <el-tooltip effect="dark" :content="title + '：' + currentTitleValue" placement="top">
                    <span>{{title}}：{{currentTitleValue}}</span>
                </el-tooltip>
            </div>
        </div>
        <div class="history-box">
            <div class="left-box">
                <img v-if="showHistory" @click="showHistory = !showHistory" src="@/assets/image/previous-step.svg" />
                <div class="history-text" @click="showHistory = !showHistory">{{showHistory?'收起历史':'查看历史'}}</div>
                <img v-if="!showHistory" @click="showHistory = !showHistory" src="@/assets/image/next-step.svg" />
            </div>
            <div class="right-box" @click="showChartsModelFun">
                <img src="@/assets/image/quanping.svg" />
            </div>
        </div>
        <div :style="{opacity:(showHistory?'1':'0')}" class="echarts" :id="echartId"></div>
    </div>
    <teleport to="body">
        <div class="show-charts-model" @click.self='showChartsModel = false' v-if="showChartsModel">
            <div class="model-echarts-box">
                <div class="brief-header">
                    <el-tooltip effect="dark" :content="title + '：' + currentTitleValue" placement="top">
                        <span>{{title}}：{{currentTitleValue}}</span>
                    </el-tooltip>
                </div>
                <div :id="modelChartsId" class="model-charts"></div>
            </div>
        </div>
    </teleport>
</template>

<script>
import * as echarts from 'echarts';
import { onMounted, ref, toRefs, watchEffect } from 'vue'
import { nanoid } from 'nanoid'
export default {
    props:{
        echarts:{
            type:Object,
            required:true,
        },
    },
    setup(props){
        const echartId = ref('')
        const title = ref('')
        const currentTitleValue = ref('')
        const showChartsModel = ref(false)
        const newProps = toRefs(props)
        const echatrsInfo = newProps.echarts.value
        echartId.value =  echatrsInfo.echartId
        const showHistory = ref(true)
        let dataLen = null
        let isMounted = false
        let myChart = null
        const modelChartsId = ref('')
        modelChartsId.value = nanoid()
        let chart = null
        let scale = 1
        const initCharts = () => {
            const newProps = toRefs(props)
            const echatrsInfo = newProps.echarts.value
            title.value = echatrsInfo.title
            currentTitleValue.value = echatrsInfo.currentTitleValue
            const { minY = 0, maxY = 100, minInterval = 0, maxInterval = 99999999, series = [], dataTips = [], yAxisName = '', yAxisLabel = {}, legend = {}, grid = {} } = echatrsInfo
            let dataLen = series[0].data.length
            if(!myChart){
                myChart = echarts.init(document.getElementById(echartId.value));
            }
            myChart.on('click', (params)=> {
                if('dataIndex' in params){
                    let dataIndex = params.dataIndex
                    let option = myChart.getOption()
                    let len = series.length
                    let dataTip = dataTips[0].dataTip
                    if(!dataTip[dataIndex]){
                        return
                    }
/*                     const { showTitle } = dataTip[dataIndex]
                    currentTitleValue.value = showTitle */
                    for(let i = 0; i < len; i++){
                        const { name } = dataTip[dataIndex]
                        option.series[i].markLine = {
                            silent:true,
                            data:[
                                {
                                    name,
                                    xAxis:dataIndex,
                                    label:{
                                        formatter:function(params){
                                            return name
                                        },
                                        fontSize:8 
                                    },
                                    lineStyle:{
                                        color: "rgba(210, 216, 253, 0.5)",
                                        width:20,
                                        type:'solid'
                                    }
                                }
                            ]
                        }
                    }
                    myChart.setOption(option)
                }
            })
            let option = {
                legend: {
                    show:false,
                    top: '4%',
                    left: 'center',
                    z: 100,
                    itemWidth:16,
                    ...legend
                },
                grid: {
                    containLabel: true,
                    top: '20%',
                    bottom: 0,
                    left: '5%',
                    right: '14%',
                    ...grid
                },
                tooltip : {
                    trigger: 'item',
                    axis: 'y',
                    formatter: function (value, index) {
                        if(typeof value.value == 'string'){
                            return value.value + ''
                        }else if(typeof value.value == 'number'){
                            return String(value.value ).split('.').length > 1 ? value.value .toFixed(2) : value.value  + '.00'
                        }
                        return ''
                    }
                },
                xAxis: {
                    type: 'category',
                    data: new Array(dataLen).fill().map((item, index)=>index+1),
                    boundaryGap:false,
                    axisLine: {
                        show: true,
                        symbol: ['none', 'arrow'],
                        symbolSize: [8, 8],
                        symbolOffset: [0, 20],
                        lineStyle: {
                            color: 'rgba(0,0,0,0.9)',
                            width: '1',
                            type: 'solid',
                            shadowOffsetX: 20
                        },
                        onZero: true
                    },
                    axisTick: {
                        show: false
                    }
                },
                yAxis: {
                    type: 'value',
                    name: yAxisName,
                    offset:1,
                    nameTextStyle: {
                        color: 'rgba(0,0,0,0.9)',
                        fontSize: 14,
                        padding: [0, 0, 10, 0]
                    },
                    max: Math.ceil(maxY) || 100,
                    min: minY || 0,
                    maxInterval,
                    minInterval,
                    splitLine: {
                        show:false
                    },
                    axisLine:{
                        show: true,
                        onZero: false,
                        symbol: ['none', 'arrow'],
                        symbolSize: [8, 8],
                        symbolOffset: [0, 20],
                        lineStyle: {
                            color: 'rgba(0,0,0,0.9)',
                            width: '1',
                            type: 'solid',
                            shadowOffsetY: -20,
                        },
                    },
                    axisLabel: yAxisLabel
                },
                series
            };
            myChart.setOption(option);
        }
        const updateCharts = (len) => {
            if(len == dataLen || !isMounted){
                return
            }
            dataLen = len
            initCharts()
        }
        const showChartsModelFun = () =>{
            showChartsModel.value = true
            const modelCharts = document.getElementById(modelChartsId.value)
            if(!modelCharts){
                setTimeout(()=>{
                    showChartsModelFun()
                },100)
                return
            }
            const newProps = toRefs(props)
            const echatrsInfo = newProps.echarts.value
            title.value = echatrsInfo.title
            currentTitleValue.value = echatrsInfo.currentTitleValue
            const { minY = 0, maxY = 100, minInterval = 0, maxInterval = 99999999, series = [], dataTips = [], yAxisName = '', yAxisLabel = {}, legend = {}, grid = {} } = echatrsInfo
            let dataLen = series[0].data.length
            chart = echarts.init(modelCharts);
            if(series[0].markArea && series[0].markArea.label){
                series[0].markArea.label.fontSize = 16
            }
            series.forEach(item=>{
                if(item.markLine){
                    item.markLine.data[0].label.fontSize = 12
                }
            })
            chart.on('click', (params)=> {
                if('dataIndex' in params){
                    let dataIndex = params.dataIndex
                    let option = chart.getOption()
                    let len = series.length
                    let dataTip = dataTips[0].dataTip
                    if(!dataTip[dataIndex]){
                        return
                    }
/*                     const { showTitle } = dataTip[dataIndex]
                    currentTitleValue.value = showTitle */
                    for(let i = 0; i < len; i++){
                        const { name } = dataTip[dataIndex]
                        option.series[i].markLine = {
                            silent:true,
                            data:[
                                {
                                    name,
                                    xAxis:dataIndex,
                                    label:{
                                        formatter:function(params){
                                            return name
                                        },
                                        fontSize:8 
                                    },
                                    lineStyle:{
                                        color: "rgba(210, 216, 253, 0.5)",
                                        width:20,
                                        type:'solid'
                                    }
                                }
                            ]
                        }
                    }
                    chart.setOption(option)
                }
            })
            let option = {
                legend: {
                    show:false,
                    top: '4%',
                    left: 'center',
                    z: 100,
                    itemWidth:24,
                    ...legend
                },
                grid: {
                    containLabel: true,
                    top: '15%',
                    bottom: 0,
                    left: '5%',
                    right: '10%',
                    ...grid
                },
                tooltip : {
                    trigger: 'item',
                    axis: 'y',
                    formatter: function (value, index) {
                        if(typeof value.value == 'string'){
                            return value.value + ''
                        }else if(typeof value.value == 'number'){
                            return String(value.value ).split('.').length > 1 ? value.value .toFixed(2) : value.value  + '.00'
                        }
                        return ''
                    }
                },
                xAxis: {
                    type: 'category',
                    data: new Array(dataLen).fill().map((item, index)=>index+1),
                    boundaryGap:false,
                    axisLine: {
                        show: true,
                        symbol: ['none', 'arrow'],
                        symbolSize: [12, 12],
                        symbolOffset: [0, 20],
                        lineStyle: {
                            color: 'rgba(0,0,0,0.9)',
                            width: '1',
                            type: 'solid',
                            shadowOffsetX: 20
                        },
                        onZero: true
                    },
                    axisTick: {
                        show: false
                    }
                },
                yAxis: {
                    type: 'value',
                    name: yAxisName,
                    offset:1,
                    nameTextStyle: {
                        color: 'rgba(0,0,0,0.9)',
                        fontSize: 24,
                        padding: [0, 0, 10, 0]
                    },
                    max: Math.ceil(maxY) || 100,
                    min: minY || 0,
                    maxInterval,
                    minInterval,
                    splitLine: {
                        show:false
                    },
                    axisLine:{
                        show: true,
                        onZero: false,
                        symbol: ['none', 'arrow'],
                        symbolSize: [12, 12],
                        symbolOffset: [0, 20],
                        lineStyle: {
                            color: 'rgba(0,0,0,0.9)',
                            width: '1',
                            type: 'solid',
                            shadowOffsetY: -20,
                        },
                    },
                    axisLabel:{
                        fontSize:16,
                        ...yAxisLabel
                    },
                },
                series
            };
            chart.setOption(option);
        }
        window.addEventListener('resize', (res)=>{
            const w = sessionStorage.getItem('w')
            if(w){
                scale = res.currentTarget.innerWidth / w
                if(myChart){
                    myChart.resize()
                    if(chart){
                        chart.resize()
                    }
                }
            }
        })
        watchEffect(()=>{
            updateCharts(newProps.echarts.value.series[0].data.length)
        })
        onMounted(()=>{
            isMounted = true
            initCharts()
        })
        return {
            echartId,
            title,
            currentTitleValue,
            showHistory,
            showChartsModel,
            showChartsModelFun,
            modelChartsId
        }
    }
}
</script>

<style lang="scss" scoped>
    .echarts-box{
        width: 100%;
        height: auto;
        .echarts-header{
            width: 100%;
            position: relative;
            .brief-header{
                width: 100%;
                font-size:16px;
                line-height: 26px;
                box-sizing: border-box;
                padding: 0 5%;
                font-weight: 550;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
        .history-box{
            width: 100%;
            display: flex;
            font-size:12px;
            line-height: 18px;
            box-sizing: border-box;
            padding: 0 5%;
            color: rgba($color: #000000, $alpha: 0.5);
            margin-top: 8px;
            align-items: center;
            cursor: pointer;
            justify-content: space-between;
            .left-box{
                display: flex;
                align-items: center;
                img{
                    width: 18px;
                    height: 18px;
                }
            }
            .right-box{
                width: 22px;
                height: 22px;
                margin-right: 32px;
                img{
                    width: 22px;
                    height: 22px;
                }
            }
        }
        .echarts{
            width: 100%;
            height: 270px;
        }
    }
    .show-charts-model{
        position: fixed;
        width: 100vw;
        height: 100vh;
        z-index: 9999;
        top: 0;
        left: 0;
        background-color:rgba($color: #000000, $alpha: 0.3);
        .model-echarts-box{
            padding: 64px;
            background: #FFFFFF;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            border-radius:16px;
            .brief-header{
                width: 100%;
                text-align: center;
                font-size: 18px;
                font-weight: 550;
            }
            .model-charts{
                width: 800px;
                height: 450px;
            }
        }
    }
</style> 