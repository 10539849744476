import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import influenceFactor from '@/views/influence-factor.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'influenceFactor',
    meta:{
      title:'影响因素'
    },
    component: influenceFactor
  },
  {
    path: '/whole-face',
    name: 'wholeFace',
    meta:{
      title:'整脸分析'
    },
    component: () => import('@/views/whole-face.vue')
  },
  {
    path: '/facial-features/left-eye',
    name: 'facialFeatures',
    meta:{
      title:'五官分析'
    },
    component: () => import('@/views/facial-features/facial-features.vue'),
    children:[
      {
        path: '/facial-features/left-eye',
        name: 'leftEye',
        meta:{
          title:'左眼'
        },
        component: () => import('@/views/facial-features/components/left-eye.vue'),
      },
      {
        path: '/facial-features/right-eye',
        name: 'rightEye',
        meta:{
          title:'右眼'
        },
        component: () => import('@/views/facial-features/components/right-eye.vue'),
      },
      {
        path: '/facial-features/left-eyebrow',
        name: 'leftEyebrow',
        meta:{
          title:'左眉'
        },
        component: () => import('@/views/facial-features/components/left-eyebrow.vue'),
      },
      {
        path: '/facial-features/right-eyebrow',
        name: 'rightEyebrow',
        meta:{
          title:'右眉'
        },
        component: () => import('@/views/facial-features/components/right-eyebrow.vue'),
      },
      {
        path: '/facial-features/nose',
        name: 'nose',
        meta:{
          title:'鼻子'
        },
        component: () => import('@/views/facial-features/components/rj-nose.vue'),
      },
      {
        path: '/facial-features/lips',
        name: 'lips',
        meta:{
          title:'嘴唇'
        },
        component: () => import('@/views/facial-features/components/rj-lips.vue'),
      }
    ]
  },
  {
    path:'/:pathMatch(.*)',
    redirect:'/'
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
